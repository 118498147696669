<template>
  <el-select
    v-model="selected"
    :placeholder="placeHolder"
    value-key="creator_id"
    filterable
    @change="changeHandle"
  >
    <el-option
      v-for="item in options"
      :key="item.creator_id"
      :label="item.creator_name"
      :value="item"
    >
    </el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    placeHolder: {
      type: String,
      default: "请选择",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: {},
      options: [],
    };
  },
  watch: {
    id: {
      handler(val) {
        this.getList(val);
      },
      immediate: true,
    },
  },
  methods: {
    changeHandle() {
      this.$emit("change", this.selected);
    },
    getList(val) {
      this.axios(
        "get",
        `${this.$pythonHost}tiktokCreatorSalary/getCreator`
      ).then((res) => {
        if (res.code == 1) {
          this.options = res.data;
          if (val) {
            this.selected = this.options.find((item) => {
              return item.creator_id == val;
            });
            this.$emit("change", this.selected);
          }
        } else if (res.code == 20001) {
          this.common.tokenExpired(res.msg);
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>