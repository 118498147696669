<template>
  <div>
    <el-card header="达人薪资" class="page_section_card">
      <keep-alive>
        <dataFilterCompVue
          ref="searchComp"
          :isType="false"
          @search="search"
        ></dataFilterCompVue>
      </keep-alive>
    </el-card>
    <el-card v-loading="loading">
      <div slot="header">
        <el-button type="primary" @click="add()">添加薪资规则</el-button>
      </div>
      <el-table :data="tableList" border stripe>
        <el-table-column
          v-for="item in columns"
          :key="item.label"
          :label="item.label"
          :prop="Array.isArray(item.prop) ? '' : item.prop"
          :align="item.align"
          :min-width="item.width"
          :fixed="item.fixed"
          :sortable="item.sortable"
        >
          <template slot-scope="scope">
            <div v-if="Array.isArray(item.prop)">
              <p>
                {{ scope.row[item.prop[0]] }} 至 {{ scope.row[item.prop[1]] }}
              </p>
            </div>
            <span v-else>
              <span v-if="item.noFormat">{{ scope.row[item.prop] }}</span>
              <span v-else>{{
                common.formatNumber(scope.row[item.prop], item.toThunsands || 2)
              }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination-vue
        @sizeChange="sizeInit"
        @currentChange="getList"
        :pageSize="postdata.page_num"
        :total="total"
        :firstPage="firstPage"
      />
    </el-card>
    <el-drawer
      append-to-body
      destroy-on-close
      :title="`${storeData.id ? '编辑' : '新增'}达人薪资目标`"
      :before-close="handleClose"
      :visible.sync="showDrawer"
      size="80%"
      direction="ltr"
      ref="drawer"
    >
      <expertSalaryStore
        :param="storeData"
        @save="saveHandle"
      ></expertSalaryStore>
    </el-drawer>
  </div>
</template>
<script>
import paginationVue from "@/components/paginationVue.vue";
import dataFilterCompVue from "../../components/dataFilterComp.vue";
import expertSalaryStore from "./store.vue";
export default {
  components: {
    dataFilterCompVue,
    paginationVue,
    expertSalaryStore,
  },
  data() {
    return {
      columns: [
        {
          label: "时间范围",
          prop: ["start_date", "end_date"],
          align: "center",
          width: 220,
          sortable: false,
          fixed: "left",
          noFormat: true,
        },
        {
          label: "达人昵称(英文)",
          prop: "creator_handle",
          align: "center",
          width: 150,
          sortable: false,
          fixed: false,
          noFormat: true,
        },
        {
          label: "达人昵称(原文)",
          prop: "creator_name",
          align: "center",
          width: 150,
          sortable: false,
          fixed: false,
          noFormat: true,
        },
        {
          label: "薪资($/月)",
          prop: "salary",
          align: "center",
          width: 120,
          sortable: false,
          fixed: false,
        },
        {
          label: "最后更新时间",
          prop: "updated_at",
          align: "center",
          width: 180,
          sortable: false,
          fixed: false,
          noFormat: true,
        },
      ],
      loading: false,
      postdata: {
        page: 1,
        page_num: 20,
      },
      tableList: [],
      total: 0,
      firstPage: 1,
      storeData: {
        id: "",
        creator_id: "",
        start_date: "",
        end_date: "",
        dateRange: [],
        salary: "",
      },
      showDrawer: false,
      gloading: null,
    };
  },
  mounted() {
    this.$refs.searchComp.search();
  },
  methods: {
    del(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios("POST", `${this.$pythonHost}tiktokCreatorSalary/delete`, {
          id: row.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getList(1);
          }
        });
      });
    },
    add() {
      this.storeData = {
        id: "",
        creator_id: "",
        start_date: "",
        end_date: "",
        dateRange: [],
        salary: "",
      };
      this.showDrawer = true;
    },
    edit(row) {
      this.storeData = { ...row };
      this.storeData.dateRange = [row.start_date, row.end_date];
      this.showDrawer = true;
    },
    saveHandle(data) {
      const pd = {
        id: data.id,
        creator_id: data.creator_obj.creator_id,
        start_date: data.dateRange[0],
        end_date: data.dateRange[1],
        salary: data.salary,
      };
      this.gloading = this.$loading({
        lock: true,
      });
      this.axios(
        "POST",
        `${this.$pythonHost}tiktokCreatorSalary/${pd.id ? "update" : "insert"}`,
        pd
      )
        .then((res) => {
          if (res.code == 1) {
            if (pd.id) {
              this.deletePersonStatis({
                creator_id: data.creator_obj.creator_id,
                id: data.id,
                type: 1,
              });
            }
            this.updatePersonStatis({
              creator_id: data.creator_obj.creator_id,
              start_date: data.dateRange[0],
              end_date: data.dateRange[1],
              type: 1,
            });
          }
        })
        .catch((err) => {
          this.gloading.close();
        });
    },
    deletePersonStatis(data) {
      this.axios(
        "POST",
        `${this.$pythonHost}profitData/profit/delete`,
        data
      ).then((res) => {});
    },
    updatePersonStatis(data) {
      this.axios(
        "POST",
        `${this.$pythonHost}profitData/profit/statistics`,
        data
      ).then((res) => {
        this.gloading.close();
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getList(1);
          this.showDrawer = false;
        }
      });
    },
    handleClose(done) {
      if (this.storeLoading) {
        return;
      }
      done();
    },
    search(data) {
      this.postdata = Object.assign(this.postdata, data);
      this.postdata.page = 1;
      this.getList(1);
    },
    getList(page) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.postdata.page = page || this.firstPage;
      this.axios(
        "get",
        `${this.$pythonHost}tiktokCreatorSalary/list`,
        this.postdata
      )
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.tableList = res.data.data;
            this.total = res.data.total_count;
            this.firstPage = res.data.page;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    sizeInit(size) {
      this.postdata.page_num = size || 20;
      this.getList(1);
    },
  },
};
</script>
