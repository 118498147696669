<template>
  <div class="display_flex">
    <div class="flex_1">
      <el-form
        :model="storeData"
        :rules="rules"
        ref="storeData"
        label-width="120px"
        label-position="right"
      >
        <el-form-item label="选择达人：" prop="creator_obj">
          <expertSelectComp
            style="width: 100%"
            placeHolder="请选择达人"
            :id="storeData?.creator_id"
            @change="getChange"
          ></expertSelectComp>
        </el-form-item>
        <template>
          <el-form-item label="选择时间：" prop="dateRange">
            <el-date-picker
              style="width: 100%"
              v-model="storeData.dateRange"
              type="monthrange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM"
              value-format="yyyy-MM"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="薪资：" prop="salary">
            <el-input
              style="width: 100%"
              placeholder="请输入数值"
              v-model="storeData.salary"
            >
              <template slot="append">美元 / 月</template>
            </el-input>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button
            type="primary"
            @click="saveHandle"
            :loading="storeLoading"
            >{{ storeLoading ? "提交中 ..." : "确 定" }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div style="padding: 0 20px" class="divider_outer">
      <el-divider style="height: 100%" direction="vertical"></el-divider>
    </div>
    <div class="flex_1">
      <p class="title_p">达人信息</p>
      <el-row
        :gutter="20"
        align="middle"
        v-show="storeData?.creator_obj?.creator_id"
      >
        <el-col :span="8">
          <el-image
            fit="cover"
            :src="storeData?.creator_obj?.creator_avatar"
            style="width: 100%"
          ></el-image>
        </el-col>
        <el-col :span="16">
          <p class="info_item">{{ storeData?.creator_obj?.creator_name }}</p>
          <p class="info_item">{{ storeData?.creator_obj?.creator_handle }}</p>
          <p class="info_item">
            <el-tag
              :type="storeData?.creator_obj?.status == 1 ? 'success' : 'danger'"
              >{{ expert_status_array[storeData?.creator_obj?.status] }}</el-tag
            >
          </p>
          <p class="info_item">
            绑定有效期：{{ storeData?.creator_obj?.effective_end_date_time }}
          </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import expertSelectComp from "@/components/expertSelectComp.vue";
export default {
  components: {
    expertSelectComp,
  },
  props: ["visible", "param"],
  data() {
    return {
      expert_status_array: {
        1: "已绑定",
        7: "未绑定-申请失效",
        9: "未绑定-已解绑",
        10: "未绑定-绑定到期",
      },
      storeLoading: false,
      storeData: {
        creator_obj: {},
        dateRange: [],
        salary: "",
      },
      rules: {
        creator_obj: [
          { required: true, message: "请选择达人", trigger: "change" },
        ],
        dateRange: [
          { required: true, message: "请选择时间范围", trigger: "change" },
        ],
        salary: [
          { required: true, message: "请输入薪资", trigger: "blur" },
          {
            pattern: /^[0-9.]+$/,
            message: "请输入正确的薪资",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    param: {
      immediate: true,
      handler(val) {
        this.storeData = { ...val };
        console.log(this.storeData);
        if (val.creator_id) {
          this.storeData.creator_obj = {
            creator_id: val.creator_id,
            creator_name: val.creator_name,
          };
        }
      },
    },
  },
  methods: {
    getChange(item) {
      this.$set(this.storeData, "creator_obj", { ...item });
      this.$forceUpdate();
    },
    saveHandle() {
      this.$refs.storeData.validate((valid) => {
        if (valid) {
          this.$emit("save", this.storeData);
        } else {
          return false;
        }
      });
    },
  },
  beforeDestroy() {
    this.$refs.storeData.resetFields();
  },
};
</script>
<style lang="scss" scoped>
.display_flex {
  .flex_1 {
    flex: 1;
    .title_p {
      font-weight: bold;
      color: #333;
      margin-bottom: 20px;
    }
  }
  .divider_outer {
    .el-divider {
      height: 100% !important;
    }
  }
  .info_item {
    margin-bottom: 14px;
    font-size: 14px;
  }
}
</style>